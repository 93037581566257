import React from "react"
import Fade from "react-reveal/Fade"
import "./style.scss"

const Story = () => {

	return (
		<div className="story">
            <div className="container">
                <Fade bottom duration={1000}>
                    <span className="caption story__caption">In the beginning, <br/> Isaac didn’t realize he was doing something special.</span>
                </Fade>
                <div className="story__wrap">
                    <Fade bottom duration={1000}>
                        <p>He started small, with just a few employees. In no time, they were flooded with clients.</p>
                    </Fade>
                    <Fade bottom duration={1000}>
                        <p>Isaac was just doing what he always did: sitting down with people and really getting to know them.</p>
                    </Fade>
                    <Fade bottom duration={1000}>
                        <p>His personal philosophy of figuring out what people want and making them comfortable bled into his work, with tremendous results. </p>
                    </Fade>
                    <Fade bottom duration={1000}>
                        <p>The custom software industry is known for the headaches it can create: time invested, endless meetings with programmers, all without knowing if the end product will be genuinely helpful or even used.</p>
                    </Fade>
                    <Fade bottom duration={1000}>
                        <p>He realized the reason customers kept coming back. There was something special about his process; he makes it pain-free.</p>
                    </Fade>
                    <Fade bottom duration={1000}>
                        <p>He realized there was a gap in the industry for the kind of smooth, easy experience he provides. </p>
                    </Fade>
                    <Fade bottom duration={1000}>
                        <p>His only question is why more companies don’t do it his way.</p>
                    </Fade>
                </div>
            </div>
		</div>
	)
}

export default Story
