import React, { useRef, useEffect, useState } from "react"
import Layout from "../components/Layout"
import Heading from "../components/Heading"
import SEO from "../components/seo"
import Loader from "../components/Loader"
import Story from "../components/Story"
import "../styles/pages-style/our-story.scss"
import img from "../images/isaac.png"

const OurStory = () => {
	const storyBlock = useRef(null)
	const [offsetStory, setOffsetStory] = useState(0)
	const [bgChangeStory, setBgChangeStory] = useState(false)

	const handleScroll = (e) => {
		let offsetTop = window.pageYOffset
		let windowHeight = window.innerHeight

		offsetTop + (windowHeight / 2) > offsetStory ? setBgChangeStory(true) : setBgChangeStory(false)
	}

	useEffect(() => {
		let bodyRect = document.body.getBoundingClientRect()

		let storyRect = storyBlock.current.getBoundingClientRect()
		let storyPos = storyRect.top - bodyRect.top
		setOffsetStory(storyPos)

		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
		
	}, [offsetStory, handleScroll])

	const [animate, setAnimate] = useState(true)

	function changeLoad() {
		setTimeout(() => {
			setAnimate(false)
		}, 500)
	}

	useEffect(() => {
		window.addEventListener('load', changeLoad(), false)
	}, [])

	return (
		<div>
			{!animate ? null : <Loader />}
			<Layout>
				<SEO title="Services | Cider Consulting" />
				<div>
					<Heading
						caption="Our Story"
						text="Meet Isaac"
                        img={img}
						bgImageClassName="bg-our-story"
					/>
                    <div ref={storyBlock} className={bgChangeStory ? 'change-bg-our-story' : ''}>
                        <Story />
                    </div>
				</div>
			</Layout>
		</div>
	)
}

export default OurStory
